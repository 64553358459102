<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <img :src="bannerImg" alt="" class="bigPicture" />
      <div class="box">
        <div class="tipOne">{{ $t("researchDirection.RD_tipOne") }}</div>
        <div class="titleOne">{{ $t("researchDirection.RD_titleOne") }}</div>
        <div class="introduceOne">
          <div>
            <div class="introduceImg">
              <img src="@/assets/img/rdBJ1.jpg" alt="" class="imgHoverBig" />
            </div>
            <div>
              <div class="introduceTitle">先进光刻工艺</div>
              <div class="introduceTitle">Advanced Lithography Technology</div>
            </div>
          </div>
          <div>
            <div class="introduceImg">
              <img src="@/assets/img/rdBJ2.jpg" alt="" class="imgHoverBig" />
            </div>
            <div>
              <div class="introduceTitle">设计制造协同优化</div>
              <div class="introduceTitle">
                Design Manufacture Co-Optimization
              </div>
            </div>
          </div>
        </div>

        <div class="boxTwo">
          <div class="titleOne titleTwo">
            {{ $t("researchDirection.RD_titleTwo") }}
          </div>
          <div class="introduceTwo">
            <div v-for="(item, index) in modular" :key="index">
              <div>
                <div class="introduceImg introduceImgTwo">
                  <img :src="item.img" alt="" class="imgHoverBig" />
                </div>
                <div>
                  <div class="introduceTitle">{{ item.title_zh }}</div>
                  <div class="introduceTitle">
                    {{ item.title_en }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="outerTwo">
      <div class="tipOne">{{ $t("researchDirection.RD_tipTwo") }}</div>

      <div class="project">
        <div
          class="projectItem"
          v-for="(item, index) in project"
          :key="index"
          @click="projectDetail(item)"
        >
          <div class="itemImg">
            <img :src="item.image" alt="" class="imgHoverBig" />
          </div>
          <div class="itemCont">
            <div class="projectTitle">{{ item.title }}</div>
            <div class="see">
              <div>{{ $t("researchDirection.RD_see") }}</div>
              <img src="@/assets/img/checklan.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：技术研发-研究方向
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-18 10:34
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "技术研发",
          title_en: "Technology R & D",
          path: "TechnologyRD",
        },
        {
          title_zh: "研究方向",
          title_en: "Research direction",
          path: "",
        },
      ],
      modular: [
        {
          title_zh: "垂直围栅晶体管动态随机存储器",
          title_en: "VCT",
          img: require("@/assets/img/rdBJ3.jpg"),
        },
        {
          title_zh: "横向堆叠三维动态随机存储器",
          title_en: "3D DRAM",
          img: require("@/assets/img/rdBJ4.jpg"),
        },
        {
          title_zh: "铟镓锌氧场效应晶体管动态随机存储器",
          title_en: "IGZO DRAM",
          img: require("@/assets/img/rdBJ5.jpg"),
        },
        // {
        //   title_zh: "垂直围栅晶体管磁存储器",
        //   title_en: "VCT MRAM",
        //   img: require("@/assets/img/rdBJ6.jpg"),
        // },
        {
          title_zh: "三维封装",
          title_en: "3D Stacking Package",
          img: require("@/assets/img/rdBJ7.jpg"),
        },
        {
          title_zh: "多功能动态随机存储器 （近存计算、存算一体等）",
          img: require("@/assets/img/rdBJ8.jpg"),
        },
      ],
      project: "",
      bannerImg: "",
    };
  },
  mounted() {
    this.getProjectList();
    this.getBigPicture();
  },
  methods: {
    /**
     * 项目详情
     * 刘嘉鑫
     * 2022-8-18
     */
    projectDetail(item) {
      this.$router.push({
        path: "RDproject",
        query: { id: item.id },
      });
    },

    /**
     * 研发项目
     * 刘嘉鑫
     * 2022-8-19
     */
    getProjectList() {
      this.$request({
        url: "/Index/projectList",
        data: {},
      }).then((res) => {
        console.log("研发项目", res);
        this.project = res.project_list;
      });
    },
    /**
     * 大图
     * 刘嘉鑫
     * 2022-8-25
     */
    getBigPicture() {
      this.$request({
        url: "/Index/imgTwo",
        data: {
          type: 6, // 类型:1=人才培养,2=研发服务,3=专利服务,4=研发条件,5=科研成果,6=研发方向,7=部门设置
        },
      }).then((res) => {
        console.log("大图", res);
        this.bannerImg = res.img;
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  background: #ffffff;
  padding: 0 260px;
}

.outerTwo {
  background: #f6f6f6;
  padding: 60px 310px;
}
.bigPicture {
  width: 100%;
  height: 400px;
  margin-bottom: 80px;
}

.box {
  padding: 0 50px;
}

.tipOne {
  font-size: 30px;
  font-family: FZFengYaSongS-GB;
  font-weight: bold;
  color: #1a2a60;
  margin-bottom: 60px;
}

.titleOne {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #222222;
  line-height: 32px;
  margin-bottom: 19px;
}

.introduceOne {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.introduceOne > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.introduceOne > div:first-child {
  margin-right: 117px;
}

.introduceImg {
  width: 180px;
  height: 180px;
  margin-bottom: 13px;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    object-fit: inherit !important;
  }
}

.introduceImgTwo {
  width: 265px !important;
}

.introduceTitle {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #1c2e68;
  text-align: center;
}

.boxTwo {
  border: 2px dashed #1a2a60;
  border-radius: 20px;
  padding: 60px 60px 0;
  margin-bottom: 80px;
}

.titleTwo {
  margin-bottom: 52px !important;
}

.introduceTwo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > div {
    width: 28%;
    text-align: center;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    > div {
      width: 66%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.project {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .projectItem {
    width: 20.7%;
    margin-right: 74px;
    margin-bottom: 60px;

    .itemImg {
      overflow: hidden;
      height: 280px;
      width: 100%;
      > img {
        height: 100%;
        width: 100%;
      }
    }

    > .itemCont {
      background: #ffffff;
      padding: 30px 20px 40px;
      display: flex;
      flex-direction: column;
      height: 70px;

      .projectTitle {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
      }

      .see {
        display: flex;
        align-items: center;
        margin-top: auto;
        > div {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #1a2a60;
          margin-right: 9px;
        }
        > img {
          width: 18px;
          height: 12px;
        }
      }
    }
  }

  .projectItem:nth-child(4n) {
    margin-right: 0 !important;
  }

  .projectItem:hover {
    .see {
      > img {
        animation: checkImg 0.7s linear infinite;
        position: relative;
      }
    }
  }
}
</style>